var pageBannerSettings = function(){
    $('.page-banner').each(function(){
        var $bannerImgSrc = $(this).find('.banner-img').attr('src');
        var $bannerImgH = $(this).find('.banner-img').height();
        var $bannerImgW = $(this).find('.banner-img').width();
        $(this).css({
            'background-image' : 'url(' + $bannerImgSrc + ')',
            //'height' : $bannerImgH
        });
        $(this).find('.banner-img').remove();
    });
};


(function( $ ) {

    $('.search-icon').on('click', function(){
        $('.search-form').toggleClass('expand');
    });

    $('#footer-contact-form').validate({ 
        rules: {
            name:{
                required: true,
            },
            email: {
                required: true,
                customEmail: true,
                maxlength: 100,
            },
            message:{
                required: true,
            },
        }
    });

}(jQuery));
