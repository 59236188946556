(function( $ ) {

//     $(window).scroll(function() {
//         var windowPos = $(this).scrollTop();
//         if(windowPos > 60){
//             $('.scroll-down').fadeOut(300);
//         }else{
//             $('.scroll-down').fadeIn(300);
//         }
//     });


	
	if (!sessionStorage.alreadyClicked) {
		setTimeout(function(){ 
			$('.logo-preloader').fadeOut(300);
		}, 3000);
		sessionStorage.alreadyClicked = 1;
	} else{
		$('.logo-preloader').hide();
	}



    $('.scroll-down').click(function(e) {
    	e.preventDefault();
        var targetLenght = $(window).height();
  
	    $('html, body').animate({
	        scrollTop: targetLenght,
	    }, 1000);
	    return false;

    });


    var toDate = function(dateStr) {
        var parts = dateStr.split("/");
        console.log(parts);
        return new Date(parts[2], parts[1] - 1, parts[0]);
    };

    var eventDate = $("#getting-started").attr('data-event-date');

    $("#getting-started").countdown(eventDate, function(event) {
        $(this).html(
            event.strftime(
                // '<p><span>%w</span>Weeks</p>' + 
                '<p><span>%D</span>Days</p>' + 
                '<p><span>%H</span>Hours</p>' + 
                '<p><span>%M</span>Minutes</p>'
                // '<p><span>%S</span>Seconds</p>'
            )
        );
    });


}(jQuery));
