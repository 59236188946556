$.validator.addMethod("money", function(value, element) {
    if(value){
        var regex = /^[0-9.,]+$/;
        if(! regex.test(value)){
            alert(value);
            return false;
        } else{
            return true;
        }
    } else{
        return true;
    }
}, $.validator.format("Please enter the correct value"));

$.validator.addMethod("customDate", function(value, element) {
    return value.match(/^\d\d?\/\d\d?\/\d\d\d\d$/);
}, $.validator.format("Please enter a date in the format dd/mm/yyyy."));

$.validator.addMethod("customText", function(value, element) {
    return value.match(/^[a-zA-Z\s]*$/);
}, $.validator.format("Please enter a valid text"));

$.validator.addMethod("nicLK", function(value, element) {
    return value.match(/^([0-9]{9}[vVxX]|[0-9]{12})$/);
}, $.validator.format("Enter a valid NIC number"));

$.validator.addMethod("customEmail", function(value, element) {
    return value.match(/^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i);
}, $.validator.format("Please enter a valid email address."));

$.validator.addMethod("customSpecialText", function(value, element) {
    return value.match(/^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!]{2,}$/);
}, $.validator.format("Please enter a valid text"));

$.validator.addMethod("companyName", function(value, element) {
    return value.match(/(?:^|\s)(?:Corporation|Corp|Inc|Incorporated|Company|LTD|PLLC|P\.C)\.?$/ig);
}, $.validator.format("Please enter a valid text"));
