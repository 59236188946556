(function( $ ) {

	$('#about-page .btn-more').click(function() {
    	$('html,body').animate({
        	scrollTop: $(".vision-and-mission").offset().top - $('#header').height()
        },'slow');
	});

	$('#our-brands-slider').owlCarousel({
	    items : 2,
	    dots: true,
	    nav: false,
	    smartSpeed: 1000,
	    slideSpeed : 1000,
	    slideBy: 2,
	    mouseDrag: false,
	    responsiveRefreshRate : 100,
	    responsive : {
		    576 : {
				items: 3,
		    },
		    768 : {
		    	items: 4,
		    },
		    992 : {
		    	items: 5,
		    },
		}
	});

}(jQuery));
