(function( $ ) {
 

 	$('#btn-upload').change(function(){
    	$('#upload-file').val($(this).val());
 	});


	// if( $('.date-input')[0] ){
 //       	$( ".date-input" ).attr( "type", "text" );
 //        $('.date-input').datetimepicker({
 //            format: 'yyyy-mm-dd'
 //        });
	// }

    // Form Tel-Country Flags
    // if( $('.phone-input')[0] ){
    // 	$(".phone-input").intlTelInput();
    // }

    // $("#input-country").easyAutocomplete({
    
    //     url: "js/json/countries.json",
    //     getValue: "name",
    //     list: {
    //         match: {
    //             enabled: true
    //         }
    //     },
    //     // template: {
    //     //     type: "custom",
    //     //     method: function(value, item) {
    //     //         return "<span class='flag flag-" + (item.code).toLowerCase() + "' ></span>" + value;
    //     //     }
    //     // },
    
    // });

}(jQuery));
