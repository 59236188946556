"use strict";

var commaSeparateRemove = function(val){
    val = parseFloat(val.replace(/,/g, ""));
    return val;
};

var commaSeparateNumber = function(val){
    while (/(\d+)(\d{3})/.test(val.toString())){
      val = val.toString().replace(/(\d+)(\d{3})/, '$1'+','+'$2');
    }
    return val;
}; 

var responsiveTable = function($tableWrapper, $table){
    var tableWrapperWidth = $tableWrapper.width();
    var tableWidth = $table.width();
    if(tableWidth > tableWrapperWidth){
        $tableWrapper.addClass('scroll');
    } else{
        $tableWrapper.removeClass('scroll');
    }
};

// with mast plugin
//$('.money').mask("#,##0", {reverse: true});
