(function( $ ) {

    var dealerNetworkBGPosition = function(){
        if($('.dealer-network-map').length){
            var networkMapWidth = $('.dealer-network-map').find('img').width();
            var networkMapLeft = $('.dealer-network-map').find('img').offset().left;
            if($(window).width() > 991){
                $('.dealer-network-map').css({
                    'backgroundImage': 'url(assets/images/dealer-network-bg.png)',
                    'backgroundPosition': networkMapLeft + networkMapWidth,
                    'backgroundRepeat': 'no-repeat',
                    'backgroundSize': 'auto 100%'
                });
            } else{
                $('.dealer-network-map').css({
                    'backgroundImage': '',
                });
            }
        }
    };

    $(document).ready(function(){
        dealerNetworkBGPosition();
        $(window).resize(function(){
            dealerNetworkBGPosition();
        });
    });
    
    $('#contact-form').validate({
        rules: {
            write_to: {
                required: true,
            },
            name: {
                required: true,
                minlength: 3,
                maxlength: 32,
            },
            email: {
                required: true,
                customEmail: true,
                maxlength: 100,
            }, 
            enquiry: {
                required: true,
                minlength: 10,
                maxlength: 3000,
            }
        },
        // messages: { }, 
        errorPlacement: function(error, element) {
            if(element.attr("name") == "write_to") {
                error.appendTo( element.parent().parent());
            } else {
                error.insertAfter(element);
            }
        }
    });

}(jQuery));